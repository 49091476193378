@import url("https://fonts.cdnfonts.com/css/inter");
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  letter-spacing: 1px;
  color: #434343;
  font-family: "Inter";
}

a {
  text-decoration: none;
}

.file-text {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  width: 80%;
  font-size: 16px !important;
}
@media only screen and (max-width: 768px) {
  .file-text {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    width: 90%;
    font-size: 16px !important;
  }
}

h2 {
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 1px;
  font-weight: bold;
}

h3 {
  font-size: 22px;
}

h4 {
  font-size: 20px;
  letter-spacing: 0.5px;
}

label,
p,
input {
  font-size: 16px;
}

ul {
  list-style: none;
}

input {
  background-color: #eff0f6;
  border-radius: 16px;
  padding: 15px 23px;
  border: none;
}
input[type="radio"] {
  display: none;
}
input[type="radio"] + label {
  position: relative;
  display: inline-block;
  padding-left: 2em;
  cursor: pointer;
  line-height: 1.4em;
  transition: all 0.3s ease-in-out;
}
input[type="radio"] + label:before,
input[type="radio"] + label:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 1.2em;
  height: 1.2em;
  text-align: center;
  color: #fafafa;
  font-family: Times;
  border-radius: 50%;
  transition: all 0.3s ease;
}
input[type="radio"] + label:before {
  transition: all 0.3s ease;
  box-shadow: inset 0 0 0 0.2em white, inset 0 0 0 1em white;
  border: 2px solid #1190cb;
}
input[type="radio"] + label:hover:before {
  transition: all 0.3s ease;
  box-shadow: inset 0 0 0 0.3em white, inset 0 0 0 1em rgba(55, 55, 55, 0.25);
}
input[type="radio"]:checked + label:before {
  transition: all 0.3s ease;
  box-shadow: inset 0 0 0 0.2em white, inset 0 0 0 1em #1190cb;
}

form {
  margin-top: 3%;
}
.form_btns {
  justify-content: center;
}
form img {
  border-radius: 18px;
}

textarea {
  border-radius: 16px;
  padding: 25px;
  border: 1px solid rgba(55, 55, 55, 0.25);
}

@media only screen and (min-width: 768px) {
  h2 {
    font-size: 48px;
    line-height: 51px;
    letter-spacing: 2px;
  }
  h3 {
    font-size: 36px;
  }
  h4 {
    font-size: 32px;
    letter-spacing: 0.7px;
  }
  form .column {
    display: flex;
    margin-bottom: 1.5rem;
  }
  form .column .check {
    flex: 1;
    margin: 20px;
  }
  label,
  p,
  input {
    font-size: 22px;
    font-weight: 300;
    line-height: 30px;
  }
  .t-large-bold {
    font-size: 26px;
  }
  .t-small-bold {
    font-size: 18px;
    line-height: 24px;
  }
  .btn.btn-l {
    padding: 16px 32px;
    font-size: 18px;
  }
  .btn.btn-xl {
    padding: 18px 36px;
    font-size: 22px;
  }
}

.header-content {
  position: sticky !important;
  top: 0;
  background-color: white;
}
.margin-home {
  margin-top: 125px !important;
}
@media only screen and (min-width: 769px) {
  .margin-home {
    margin-top: 160px !important;
  }
}

.container {
  padding: 0 20px;
  max-width: 1272px;
  margin: auto;
}

.t-large-bold {
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  display: flex;
  align-items: center;
  letter-spacing: 0.0015em;
  padding: 35px 18.1185px 0px;
}

.t-large-normal {
  padding: 0px 18.1185px 0px;
}

.t-small {
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 30px;
}

.t-small-bold {
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
}

.input_form {
  margin-bottom: 35px;
}

.box-consulta {
  padding: 5%;
  margin: 1rem 0 7rem;
  border-radius: 18px;
}

.flex {
  display: flex;
}

.miga {
  padding: 2rem 0 1rem;
  display: flex;
  align-items: center;
}
.miga img {
  margin-left: 10px;
  width: 15px;
}
.title-miga {
  font-size: 16px !important;
  font-weight: 300;
  line-height: 30px;
}
p {
  font-size: 16px !important;
  font-weight: 300;
  line-height: 30px;
}

label {
  font-size: 16px !important;
  font-weight: 300;
  line-height: 30px;
}

input {
  font-size: 16px !important;
}

@media only screen and (min-width: 768px) {
  .title-miga {
    font-size: 22px !important;
  }
  p {
    font-size: 22px !important;
  }
  label {
    font-size: 22px !important;
  }

  input {
    font-size: 22px !important;
  }
}
.check {
  display: flex;
  flex-direction: column;
}
.check label {
  margin-bottom: 1%;
}

.btn {
  display: flex;
  align-items: center;
  border-radius: 10px;
  font-weight: 600;
}
.btn.btn-xs {
  padding: 12px 24px;
  height: 44px;
  font-size: 16px;
}
.btn.btn-md {
  padding: 14px 28px;
  height: 52px;
  font-size: 18px;
}
.btn.btn-l {
  padding: 16px 32px;
  font-size: 18px;
}
.btn.btn-xl {
  padding: 18px 36px;
  font-size: 22px;
}
.btn.lineal {
  border: 2px solid #1190cb;
  color: #434343;
}
.btn.lineal:hover {
  border: 2px solid #f57619;
  color: #434343;
}
.btn.lineal:active {
  border: 2px solid #186096;
}
.btn.full {
  background-color: #1190cb;
  color: #f8f1f1;
}
.btn.full:hover {
  background-color: #f57619;
}
.btn.full:active {
  background-color: #186096;
}
.btn.lineal.inactive,
.btn.full.inactive {
  opacity: 0.5;
}
.btn.lineal.inactive:hover,
.btn.full.inactive:hover {
  background-color: #1190cb;
}

.form_btns {
  padding: 32px 16px;
  display: flex;
}

@media only screen and (max-width: 768px) {
  .form_btns {
    padding: 32px 16px;
    display: contents;
  }
  .form_btns .btn {
    display: block !important;
  }
  .form_btns .center-mobile {
    text-align: center;
  }
  .form_btns .width-100 {
    width: 100%;
  }
  .form_btns .margin-top {
    margin-top: 10px;
  }
  .form_btns .margin-bottom {
    margin-bottom: 10px;
  }
}

.form_btns :first-child {
  margin-right: 20px;
}
.form_btns_success {
  padding: 32px 16px;
  display: flex;
}

.form_btns_size {
  font-size: 14px !important;
}

@media only screen and (min-width: 768px) {
  .form_btns_size {
    font-size: 18px !important;
  }
}

.box_shadow {
  box-shadow: 0px 0px 30px 0px rgba(79, 79, 79, 0.3);
}

.img-home-exp {
  display: flex;
  justify-content: center;
}

.hide {
  display: none !important;
}

@media only screen and (max-width: 768px) {
  .hide-mobile {
    display: none !important;
  }
}

@media only screen and (min-width: 768px) {
  .hide-desktop {
    display: none !important;
  }
}

.error-message {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  border: 1px solid #e8200c;
  border-radius: 8px;
  padding: 15px;
  gap: 3px;
}

.error-logo {
  display: flex;
  align-items: center;
}

#error-logo {
  background-image: url("../img/error.svg");
  width: 17px;
  height: 17px;
  background-position: 0 0;
  display: flex;
  align-items: center;
}

.error-message-text {
  display: flex;
  justify-content: start;
  gap: 10px;
}

header {
  padding: 12px 0;
  position: fixed;
  top: 0px;
  width: 100%;
  background-color: #ffff;
}
header .box_header {
  display: flex;
}

#logo {
  background-image: url("../img/logo.svg");
  width: 151px;
  height: 77px;
  background-position: 0 0;
}

@media only screen and (min-width: 769px) {
  header {
    padding: 20px 0;
    position: fixed;
    top: 0px;
    width: 100%;
    background-color: #ffff;
    z-index: 99;
  }
  header .box_header {
    align-items: center;
  }
  header .nav,
  header ul {
    display: flex;
    flex-grow: 1;
  }
  header ul li {
    padding: 12px 16px;
  }
  header ul li a {
    color: #1190cb;
    font-weight: 600;
  }
  header ul li:hover a {
    color: #f57619;
  }
  header .consulta {
    display: flex;
    align-items: center;
  }
  header .consulta a {
    color: #f8f1f1;
  }
  header .consulta #logo {
    margin-right: 16px;
  }
}
.flecha {
  background-image: url("../img/iconos.svg");
  width: 30px;
  height: 30px;
  background-position: 0 -25px;
  background-repeat: no-repeat;
  background-size: 80px;
  margin-left: 16px;
}
.banner {
  margin-top: 1.875em;
  margin-bottom: 2em;
}
.banner .text p {
  margin: 16px 0;
}
.banner:first-child .text {
  margin: 0 0 1.875em 0;
}
.banner img {
  width: 100%;
  border-radius: 15.2568px;
}

.experiencias {
  margin: 60px 0;
}
.experiencias h2 {
  max-width: 480px;
  margin-bottom: 48px;
}
.experiencias .flex {
  display: flex;
  justify-content: space-between;
  max-width: 1094px;
  margin: auto;
}
.experiencias .caso {
  text-align: center;
  max-width: 516px;
}
.experiencias .caso .user {
  margin: 24px 0;
}
.experiencias .caso .user p {
  font-size: 18px;
}
.experiencias .caso img {
  border-radius: 40px;
}

@media only screen and (min-width: 769px) {
  .banner {
    display: flex;
    align-items: center;
    margin: 3.75em 0;
  }
  .banner:nth-child(2) {
    flex-direction: row-reverse;
  }
  .banner .text p {
    margin: 32px 0;
  }
  .banner:first-child .text {
    margin: 0 3.75em 0 0;
  }
  .banner:nth-child(2) .text {
    margin-left: 60px;
  }
}
.ayuda {
  background-color: #186096;
}
.ayuda h2,
.ayuda p {
  color: #fafafa;
}
.ayuda h2 {
  text-align: center;
}
.ayuda .box_ayuda {
  padding-top: 8%;
  padding-bottom: 5%;
}
.ayuda p:first-child {
  font-weight: bold;
}
.ayuda .conversemos {
  padding-top: 8%;
  padding-bottom: 8%;
}
.ayuda .conversemos > p {
  text-align: center;
}
.ayuda .conversemos .item {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}
.ayuda .conversemos .item .icon {
  width: 43px;
  height: 43px;
  margin: 5px;
  background-image: url("../img/iconos.svg");
  color: #fafafa;
}
.ayuda .conversemos .item .datos {
  margin-left: 16px;
}
.ayuda .conversemos .icon-phone{
  background-position-x: 95px;
}

@media only screen and (min-width: 769px) {
  .ayuda h2 {
    max-width: 400px;
    text-align: initial;
  }
  .ayuda .box_ayuda {
    display: flex;
    align-items: center;
    padding-top: 2%;
    padding-bottom: 2%;
  }
  .ayuda .conversemos {
    margin-left: 16%;
  }
  .ayuda .conversemos > p {
    text-align: initial;
  }
  .ayuda .conversemos .box_conversemos {
    display: flex;
  }
  .ayuda .conversemos .item {
    justify-content: flex-start;
  }
  .ayuda .conversemos .item:last-child {
    margin-left: 32px;
  }
}

.container_consulta {
  border-radius: 18px;
  padding: 0px 16px 16px;
  background-color: #fafafa;
}
.container_consulta > p:first-child {
  margin-bottom: 10px;
}
.box_consulta {
  display: flex;
  margin-top: 4%;
}
.column:first-child {
  flex-grow: 1;
}
form .column .check {
  margin: 0 0;
}
form .column .check label {
  margin-bottom: 1%;
}
form .column p {
  margin-bottom: 5%;
}
.bc_list {
  margin-bottom: 25px;
  margin-left: -16px;
}
.bc_list .item {
  background-color: #f7f7fc;
  cursor: pointer;
}
.bc_list .item .select_click {
  font-size: 22px;
  font-weight: 500;
  padding: 20px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bc_list_select {
  background-color: #fafafa;
}
.bc_list_image {
  height: fit-content;
}
.bc_image {
  border-radius: 15.2568px;
  margin-bottom: 25px;
}
.check {
  padding: 20px 30px;
}

@media only screen and (max-width: 769px) {
  .padding-input {
    padding: 0 !important;
  }
}

.enviado {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 8% 0 5%;
  margin-bottom: 10%;
}
.enviado p {
  max-width: 500px;
  margin: 2% 0;
}
.enviado img {
  margin-top: 1%;
}
.upload {
  background-color: white;
  border: 1px solid rgba(55, 55, 55, 0.25);
  border-radius: 16px;
  height: 56px;
  color: grey;
  font-size: 16px;
  line-height: 23px;
  overflow: hidden;
  padding: 16px;
  position: relative;
  resize: none;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.upload .icon {
  background-image: url("../img/upload.png");
  height: 16px;
  width: 20px;
}
.upload .input-file {
  cursor: pointer !important;
  display: block;
  filter: alpha(opacity=0);
  opacity: 0;
  position: absolute;
  right: 0px;
  top: 0px;
  index: 1;
  height: 100%;
  left: 0;
  border: 1px solid rgba(55, 55, 55, 0.25);
}

.banner-comite .box-banner {
  background-color: #1190cb;
  display: flex;
  align-items: center;
}
.banner-comite .box-banner h2,
.banner-comite .box-banner p {
  color: #f8f1f1;
}
.banner-comite .box-banner h2 {
  margin: 10px 0;
}
.banner-comite .box-banner .text {
  padding: 64px;
}

.custom {
  display: flex;
  border-radius: 10px;
}
.custom input[type="number"] {
  box-shadow: none;
  outline: none;
  width: 100%;
  height: 60px;
  text-align: center;
  border-radius: 0;
}
.custom input[type="number"]:focus {
  border-color: #57b8f6 !important;
}
.custom input[type="number"]:hover {
  border-color: #a5a5a5;
}
.custom .quantity-button {
  display: flex;
  align-items: center;
  width: 60px;
  justify-content: center;
  background-color: #1190cb;
  color: #f8f1f1;
  cursor: pointer;
}
.custom .quantity-remove {
  border-radius: 10px 0 0 10px;
}
.custom .quantity-remove::after {
  display: none;
}
.custom .quantity-add {
  border-radius: 0 10px 10px 0;
}
.custom .quantity-add::after {
  transform: rotate(90deg);
}

@media only screen and (min-width: 769px) {
  #registro .form_btns {
    justify-content: flex-end;
  }
  .banner-comite {
    display: flex;
    height: 400px;
  }
  .banner-comite .box-banner {
    width: 100%;
  }
  .banner-comite img {
    -o-object-fit: cover;
    object-fit: cover;
    max-width: 50%;
  }
  .banner-comite .text {
    max-width: 680px;
    margin-left: auto;
  }
  .form-comite {
    display: flex;
  }
  .form-comite .column {
    width: 100%;
  }
}
footer {
  background-color: #1190cb;
  padding: 60px 0;
}
footer a,
footer p {
  color: #eff0f6;
  font-weight: 600;
  font-size: 18px;
}
footer .logo {
  background-image: url("../img/logo.svg");
  width: 151px;
  height: 77px;
  background-position-y: bottom;
  margin-right: 16px;
}
footer .footer-box {
  display: flex;
  padding-bottom: 45px;
}
footer .footer-box ul {
  margin-right: 12%;
}
footer .footer-box ul li {
  margin: 34px 0;
}
footer .footer-box ul li:first-child a {
  color: #f8f1f1;
  font-size: 24px;
}

.consultas .container_consulta {
  padding: 1.5rem;
  margin: 2rem 0;
  border-radius: 18px;
  background-color: #fafafa;
}
.consultas .container_consulta > p:first-child {
  margin-bottom: 10px;
}
.consultas .container_consulta .box_consulta {
  margin-top: 1rem;
}
.consultas .container_consulta .bc_list .item {
  background-color: #eff0f6;
}
.consultas .container_consulta .bc_list .item .select_click {
  font-size: 22px;
  font-weight: 500;
  padding: 20px 30px;
}
.consultas .container_consulta .bc_list_select {
  background-color: #fafafa;
}
.consultas .container_consulta .bc_list_select .check {
  padding: 15px 20px;
  margin: 0 0 10px;
  background-color: #ffffff;
}
.consultas .container_consulta .form_btns {
  display: flex;
  justify-content: space-between;
  margin: 3rem 0;
}
.consultas .enviado {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 8% 0 5%;
  margin-bottom: 10%;
}
.consultas .enviado p {
  max-width: 500px;
  margin: 3% 0;
}
.consultas .enviado .form_btns {
  margin: 3% 0;
}
.consultas .enviado img {
  margin-top: 3%;
}
.consultas .upload {
  background-color: white;
  border: 1px solid rgba(55, 55, 55, 0.25);
  border-radius: 16px;
  height: 56px;
  color: grey;
  font-size: 16px;
  line-height: 23px;
  overflow: hidden;
  padding: 16px;
  position: relative;
  resize: none;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
}
.consultas .upload .icon {
  background-image: url("../img/upload.png");
  height: 16px;
  width: 20px;
}
.consultas .upload .input-file {
  cursor: pointer !important;
  display: block;
  filter: alpha(opacity=0);
  opacity: 0;
  position: absolute;
  right: 0px;
  top: 0px;
  index: 1;
  height: 100%;
  left: 0;
  border: 1px solid rgba(55, 55, 55, 0.25);
}

@media only screen and (min-width: 768px) {
  .consultas .container_consulta {
    padding: 1.5rem 2rem;
  }
  .consultas .container_consulta .box_consulta {
    display: flex;
    flex-wrap: wrap;
  }
  .consultas .container_consulta .box_consulta .column {
    flex-basis: 50%;
    margin-bottom: 4rem;
  }
  .consultas .container_consulta .box_consulta .inputs {
    flex-basis: 90%;
  }
  .consultas .container_consulta .box_consulta .inputs p {
    margin: 0 20px 20px;
  }
  .consultas .container_consulta .form_btns {
    order: 3;
    justify-content: center;
    margin: 0;
  }
  .consultas .container_consulta .form_btns :first-child {
    margin-right: 20px;
  }
}
